import { APA_RELATED_TRANING_REQUEST, APA_RELATED_TRANING_SUCCESS, APA_RELATED_TRANING_FAIL } from "../../Constant/APARelatedTraning/APARelatedTraningConstant";

  

export const getAPARelatedTraningReducer = (state = { apaRelatedTraning: [], loading: false, }, action) => {

    switch (action.type) {
        case APA_RELATED_TRANING_REQUEST:
      return {
        ...state,
        loading: true, 
      };

        case APA_RELATED_TRANING_SUCCESS:
            return {
                ...state,
                loading: false,
                apaRelatedTraning: action.payload
            }

          
        case APA_RELATED_TRANING_FAIL:
                  return {
                    ...state,
                    loading: false,
                    error:action.error
                  }

        default:
            return state
    }


};
