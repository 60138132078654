import { APA_EXPART_POOL_REQUEST, APA_EXPART_POOL_SUCCESS, APA_EXPART_POOL_FAIL } from "../../Constant/APAExpertPool/APAExpertPoolConstant";



export const getAPAExpertPoolReducer = (state = { apaExpertPool: [], loading: false, }, action) => {

    switch (action.type) {
        case APA_EXPART_POOL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case APA_EXPART_POOL_SUCCESS:
            return {
                ...state,
                loading: false,
                apaExpertPool: action.payload
            }
        case APA_EXPART_POOL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }


};
